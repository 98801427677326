import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'reactstrap';
import './styles.scss';

const logo = require('assets/img/logo.png');

const LoginForm = ({ handleSubmit, children, error }) => (
  <Form onSubmit={handleSubmit} className="loginForm">
    <div className="text-center mb-4 pt-4">
      <img src={logo} className="logo" alt="logo" />
    </div>
    {error && <p className="text-message py-3">Lütfen giriş bilgilerinizi kontrol ediniz.</p>}
    {children}
    <Button blue size="lg" className="bg-blue border-0 my-4" block onClick={handleSubmit}>
      Giriş Yap
    </Button>
  </Form>
);

LoginForm.defaultProps = {
  error: false,
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  error: PropTypes.bool,
};

export default LoginForm;
