import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { MdWeb } from 'react-icons/md';
import { MainLayout } from 'components/Layout';
import Page from 'components/Page';
import { Row, Col, Card, Button } from 'reactstrap';
import Table from 'components/Table/Table';
import DatePicker from 'components/DatePicker';
import SelectBox from 'components/SelectBox';
import uniqBy from 'lodash/uniqBy';
import request from '@b5a/request';
import { filter } from 'lodash';

const { getCashFlow, getCustomerBanks } = request;

const CahFlowPage = () => {
  const [selectedListRange, setSelectedListRange] = useState({ label: 'Günlük', value: 0 });
  const [filters, setFilters] = useState({});
  const [tableData, setTableData] = useState([]);
  const [customerBanks, setCustomerBanks] = useState([]);

  const setFilterItems = (params) => {
    setFilters({ ...filters, ...params });
  };

  const onListRangeSelectChange = (value) => {
    setSelectedListRange(value);
  };

  const submitButton = () => {
    const { transactionDate } = filters;
    const newRequestData = {
      companyId: 1,
      startDate: transactionDate.startDate.format('YYYY-MM-DD'),
      endDate: transactionDate.endDate.format('YYYY-MM-DD'),
      requestType: selectedListRange.value,
      bankCode: filters.bankCode?.value || null,
    };
    getCashFlowData(newRequestData);
  };

  const dataListRangeOptions = [
    { label: 'Günlük', value: 0 },
    { label: 'Haftalık', value: 1 },
    { label: 'Aylık', value: 2 },
  ];

  const columns = [
    {
      Header: 'Valör',
      accessor: 'valorDate',
      Cell: ({ value }) => <span className="number">{value}</span>,
    },
    {
      Header: 'Banka Kodu',
      accessor: 'bankCode',
    },
    {
      Header: 'Banka Adı',
      accessor: 'bankName',
    },
    {
      Header: 'İşlem Tutarı',
      accessor: 'transactionAmount',
      Cell: ({ value }) => <span>{new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(value)} TL </span>,
      Footer: (
        <span>
          <strong>
            {new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(
              tableData.reduce((total, item) => {
                if (item.transactionAmount !== 'NULL') {
                  total += Number(item.transactionAmount);
                }
                return total;
              }, 0),
            )}{' '}
            TL
          </strong>
        </span>
      ),
    },
    {
      Header: 'Banka Komisyon Tutarı',
      accessor: 'bankCommissionAmount',
      Cell: ({ value }) => <span>{new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(value)} TL</span>,
      Footer: (
        <span>
          <strong>
            {new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(
              tableData.reduce((total, item) => {
                if (item.bankCommissionAmount !== 'NULL') {
                  total += Number(item.bankCommissionAmount);
                }
                return total;
              }, 0),
            )}{' '}
            TL
          </strong>
        </span>
      ),
    },
    {
      Header: 'Fiili Net Tutar',
      accessor: 'netActualAmount',
      Cell: ({ value }) => <span>{new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(value)}</span>,
      Footer: (
        <span>
          <strong>
            {/* {data[selectedListRange.value].reduce((total, item) => {
              if (item.netActualAmount !== 'NULL') {
                total += Number(item.netActualAmount);
              }
              return total;
            }, 0)} */}
          </strong>
        </span>
      ),
    },
    {
      Header: 'İşlem Adeti',
      accessor: 'numberOfTransactions',
    },
  ];

  const clearFilter = () => {
    setTableData([]);
    setFilters({});
  };

  const getCashFlowData = async (data) => {
    try {
      const response = await getCashFlow(data);
      setTableData(response.data.data.transactions);
    } catch (error) {}
  };
  const getCustomerBanksData = async (data) => {
    try {
      const response = await getCustomerBanks(data);
      const bankOptions = response.data.reduce((total, item) => {
        total.push({ label: item.name, value: item.code });
        return total;
      }, []);
      setCustomerBanks(bankOptions);
    } catch (error) {}
  };
  useEffect(() => {
    getCashFlowData({ companyId: 1, requestType: 0 });
    getCustomerBanksData({ companyId: 1 });
  }, []);

  return (
    <MainLayout>
      <Page className="cashflowPage px-3" title="Nakit Akışı" icon={MdWeb}>
        <Row className="mx-1">
          <Col className="col-12 mb-3" style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
            <Card className=" p-4 shadow-c">
              <Row className="d-flex justify-content-center align-items-center">
                <Col sm="12" md="3">
                  <DatePicker
                    blockAfterDates={false}
                    label="Tarih Aralığı"
                    onChange={({ startDate, endDate }) => {
                      setFilterItems({
                        transactionDate: { startDate, endDate },
                      });
                    }}
                  />
                </Col>
                <Col sm="12" md="2">
                  <SelectBox
                    isClearable={false}
                    label="Listeleme Aralığı"
                    selectedValues={selectedListRange}
                    options={dataListRangeOptions}
                    onSelectChange={(value) => onListRangeSelectChange(value)}
                  />
                </Col>
                <Col sm="12" md="3">
                  <SelectBox
                    label="Banka"
                    selectedValues={filters.bankCode}
                    options={customerBanks}
                    onSelectChange={(value) => {
                      setFilterItems({
                        bankCode: value,
                      });
                    }}
                  />
                </Col>
                <Col sm="12" md="3">
                  <Button sm="12" md="12" block outline color="success" className="mt-3" onClick={submitButton}>
                    Filtrele
                  </Button>
                </Col>
                {/* <Col sm="12" md="2">
                  <Button sm="12" md="12" block outline color="success" className="mt-4" onClick={clearFilter}>
                    Temizle
                  </Button>
                </Col> */}
              </Row>
            </Card>
          </Col>
        </Row>
        <Row className="mx-1">
          <Col className="col-12 mb-3" style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
            <Card className="table-card p-4 shadow-c">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                className="mb-2">
                <div className="justify-content-start" style={{ color: '#90a0b7' }}>
                  {`${tableData.length} Adet sonuç bulundu.`}
                </div>
                <div className="justify-content-end mb-1">
                  <Button outline color="info" className="mr-4" onClick={() => {}}>
                    <i className="fas fa-paper-plane mr-2" />
                    Mail Gönder
                  </Button>

                  <Button outline color="primary" className="mr-4" onClick={() => {}}>
                    <i className="fas fa-file-csv mr-2" />
                    .CSV'e aktar
                  </Button>

                  <Button outline color="success">
                    <i className="fas fa-file-excel mr-2" />
                    .XSLX'e aktar
                  </Button>
                </div>
              </div>
              <Table data={tableData} columns={columns} pageCount={15} />
            </Card>
          </Col>
        </Row>
      </Page>
    </MainLayout>
  );
};

export default CahFlowPage;
