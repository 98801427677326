import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import CardContent from 'components/Card';
import logo from 'assets/img/logo-text.png';

const LoginSytemContent = () => {
  return (
    <>
      <Row>
        <Col>
          <div color="light" className="text-center ">
            <img width="160" src={logo} alt="logo" className="m-3" />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div color="light" className="text-center shadow-sm company-login-header">
            GİRİŞ YAPMAK İSTEDİĞİNİZ SİSTEMİ LÜTFEN SEÇİNİZ.
          </div>
        </Col>
      </Row>
      <Row className="company-login-layout">
        <Col sm="12" md="6" className="company-login-layout mt-4 ">
          <CardContent data={{ headerText: 'NAKİT AKIŞI' }} className="shadow-sm w-100 custom-cart-content">
            <div className="p-1 mb-4 text-center">
              Entegre çalıştıgınız tüm bankalardaki hesaplarınızı, bakiyelerinizi ve hesap hareketlerini takip
              edebilirsiniz.
            </div>
            <Button
              color="primary"
              className="w-100"
              outline
              onClick={() => {
                const token = localStorage.getItem('token');
                window.location.href = `http://test.ehesapp.com/loginWithToken?token=${token}`;
              }}>
              Devam Et
            </Button>
          </CardContent>
        </Col>
        <Col sm="12" md="6" className="company-login-layout mt-4">
          <CardContent data={{ headerText: 'POS AKIŞI' }} className="shadow-sm w-100 custom-cart-content">
            <div className="p-1 mb-4 text-center">
              Pos cihazlarınızın ve banka anlaşmalarınızın grafiksel ve tablo olarak tek bir ekrande takip
              edebilirsiniz.
            </div>
            <Button
              color="primary"
              className="w-100"
              outline
              onClick={() => {
                window.location.href = '/';
              }}>
              Devam Et
            </Button>
          </CardContent>
        </Col>
      </Row>
    </>
  );
};
export default LoginSytemContent;
