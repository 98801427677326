import React from 'react';
import PropTypes from 'prop-types';

import { Card, CardHeader, CardBody, CardFooter } from 'reactstrap';

const CardContent = ({ data, children, ...props }) => {
  const { headerText, footerText } = data;
  return (
    <Card {...props}>
      {headerText && <CardHeader>{headerText}</CardHeader>}
      <CardBody>{children}</CardBody>
      {footerText && <CardFooter className="text-muted">{footerText}</CardFooter>}
    </Card>
  );
};

CardContent.defaultProps = {
  data: {
    headerText: '',
    footerText: '',
  },
};

CardContent.propTypes = {
  data: PropTypes.shape({
    headerText: PropTypes.string,
    footerText: PropTypes.string,
  }),
};

export default CardContent;
