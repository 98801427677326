import React from 'react';

import classNames from 'classnames';

import { Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';

const IconWidget = ({ bgColor, icon: Icon, iconProps, label, title, subtitle, className, ...restProps }) => {
  const classes = classNames('cr-widget', className, {
    [`bg-${bgColor}`]: bgColor,
  });
  return (
    <Card inverse className={classes} {...restProps}>
      <CardBody className="cr-widget__icon">
        <CardTitle className="font-weight-bold" style={{ textAlign: 'center' }}>
          {label}
        </CardTitle>
        <Icon size={50} {...iconProps} />
      </CardBody>
      <CardBody style={{ textAlign: 'right', marginTop: '3%' }}>
        <CardTitle style={{ fontWeight: 'bold', fontSize: 24 }}>
          {new Intl.NumberFormat('tr-TR', { currency: 'EUR' }).format(title)} TL
        </CardTitle>
        <CardSubtitle>{subtitle}</CardSubtitle>
      </CardBody>
    </Card>
  );
};

IconWidget.propTypes = {};

IconWidget.defaultProps = {
  bgColor: 'primary',
  icon: 'span',
  iconProps: { size: 50 },
};

export default IconWidget;
