import Axios from '@b5a/services/axios';

class Services {
  constructor({ baseURL }) {
    const axios = new Axios({ baseURL });
    return {
      axios: axios.axios,
      setHeaders: axios.setHeaders,
    };
  }
}

export default Services;
