import { Switch, BrowserRouter, Redirect } from 'react-router-dom';
import React from 'react';
import PublicRoute from 'routers/PublicRoute';
import PrivateRoute from 'routers/PrivateRoute';
import * as Pages from 'pages';

const isSession = localStorage.getItem('session');

const redirect = isSession ? '/' : '/giris';

/**
 *   { to: '/nakit-akisi', name: 'Nakit Akışı', exact: false, Icon: MdWeb },
  { to: '/gun-sonu-raporu', name: 'Gün Sonu raporu', exact: false, Icon: MdInsertChart },
  { to: '/bloke-cozum-raporu', name: 'Bloke Çözüm Raporu', exact: false, Icon: MdWidgets },
  { to: '/anlasmalar', name: 'Anlaşmalar', exact: false, Icon: MdWidgets },
];
 */
const Routes = () => {
  const {
    DashboardPage,
    CahFlowPage,
    PosOperationPage,
    EndOfDayZReportPage,
    AgreementsPage,
    LoginPage,
    CompanyLoginSystem,
  } = Pages;

  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute exact path="/" component={DashboardPage} />
        <PrivateRoute path="/anasayfa" component={DashboardPage} />
        <PrivateRoute path="/nakit-akisi" component={CahFlowPage} />
        <PrivateRoute path="/pos-islemleri" component={PosOperationPage} />
        <PrivateRoute path="/gun-sonu-raporu" component={EndOfDayZReportPage} />
        <PrivateRoute path="/anlasmalar" component={AgreementsPage} />
        <PrivateRoute path="/bloke-cozum-raporu" component={DashboardPage} />
        <PrivateRoute path="/sirket-sistem-girisi" component={CompanyLoginSystem} />
        <PublicRoute path="/giris" component={LoginPage} />
        <Redirect to={redirect} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
