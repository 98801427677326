import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import LoginSytemContent from './components';
import './components/styles.scss';

const CompanyLoginSystem = () => (
  <div className="company-login-system">
    <Container>
      <Row className="d-flex justify-content-center">
        <Col md={8}>
          <LoginSytemContent />
        </Col>
      </Row>
    </Container>
  </div>
);
export default CompanyLoginSystem;
