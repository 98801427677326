import Tab from 'components/Tab';
import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import BusinessLoginForm from './components/BusinessLoginForm';
import CompanyLoginForm from './components/CompanyLoginForm';

const tabData = [
  {
    key: '1',
    tabName: 'Şirket Girişi',
  },
  {
    key: '2',
    tabName: 'İş Yeri Girişi',
  },
];

const tabContent = [
  { key: '1', content: <CompanyLoginForm /> },
  { key: '2', content: <BusinessLoginForm /> },
];

const LoginPage = () => (
  <Row className="row-login mx-0">
    <Col md={6} lg={4}>
      <Card body>
        <Tab tabData={tabData} tabContent={tabContent} />
      </Card>
    </Col>
  </Row>
);

export default LoginPage;
