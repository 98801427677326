import { Content, Footer, Header, Sidebar } from 'components/Layout';
import React from 'react';

const MainLayout = ({ children }) => (
  <main className="cr-app bg-light">
    <Sidebar />
    <Content fluid>
      <Header />
      {children}
      <Footer />
    </Content>
  </main>
);

export default MainLayout;
