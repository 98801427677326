import React from 'react';

import { Navbar, Nav, NavItem } from 'reactstrap';

const Footer = () => (
  <Navbar>
    <Nav navbar>
      <NavItem />
    </Nav>
  </Navbar>
);

export default Footer;
