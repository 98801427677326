import LoginForm from 'components/Login/LoginForm';
import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import req from '@b5a/request/index';
import './styles.scss';

const { login } = req;

const isSignup = false;

const CompanyLoginForm = (props) => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState(false);

  const campanyHandleSubmit = async () => {
    try {
      const loginResponse = await login(email, password);
      localStorage.setItem('session', true);
      localStorage.setItem('token', loginResponse.data.token);
      window.location = '/sirket-sistem-girisi';
    } catch (err) {
      setError(true);
    }
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      campanyHandleSubmit();
    }
  };

  const {
    usernameLabel,
    usernameInputProps,
    passwordLabel,
    passwordInputProps,
    confirmPasswordLabel,
    confirmPasswordInputProps,
  } = props;

  return (
    <LoginForm handleSubmit={campanyHandleSubmit} error={error}>
      <FormGroup>
        <Label for={usernameLabel} className="customInputLabel">
          {usernameLabel}
        </Label>
        <Input
          {...usernameInputProps}
          onBlur={() => {
            setError(false);
          }}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label for={passwordLabel} className="customInputLabel">
          {passwordLabel}
        </Label>
        <Input
          {...passwordInputProps}
          onBlur={() => {
            setError(false);
          }}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          onKeyUp={handleKeyUp}
        />
      </FormGroup>
      {isSignup && (
        <FormGroup>
          <Label for={confirmPasswordLabel}>{confirmPasswordLabel}</Label>
          <Input {...confirmPasswordInputProps} />
        </FormGroup>
      )}
      <FormGroup check>
        <Label check>
          <Input type="checkbox" />
          {isSignup ? 'Agree the terms and policy' : 'Beni Hatırla'}
        </Label>
      </FormGroup>
    </LoginForm>
  );
};

CompanyLoginForm.defaultProps = {
  usernameLabel: 'Kullanıcı Adı',
  usernameInputProps: {
    type: 'username',
    placeholder: '',
    className: 'customInput py-4',
  },
  passwordLabel: 'Şifre',
  passwordInputProps: {
    type: 'password',
    placeholder: '******',
    className: 'customInput py-4',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};

export default CompanyLoginForm;
