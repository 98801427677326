import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { MainLayout } from 'components/Layout';
import Page from 'components/Page';
import { Row, Col, Card, Button } from 'reactstrap';
import Table from 'components/Table/Table';
import uniqBy from 'lodash/uniqBy';
import { MdInsertChart, MdAccessTime } from 'react-icons/md';
import { IoCheckmarkDoneCircleOutline, IoCloseCircleOutline } from 'react-icons/io5';
import { Tooltip } from 'react-tippy';

import DatePicker from 'components/DatePicker';
import SelectBox from 'components/SelectBox';
import data from './mockData2.json';

import './style.scss';

import request from '@b5a/request';
const { getEndOfDay, getCustomerBanks } = request;

const getTip = (value) => {
  if (value.info === 'rejected') {
    return <IoCloseCircleOutline style={{ fontSize: 30, color: 'red' }} />;
  }
  if (value.info === 'done') {
    return <IoCheckmarkDoneCircleOutline style={{ fontSize: 30, color: 'green' }} />;
  }
  return <MdAccessTime style={{ fontSize: 30, color: 'grey' }} />;
};

const PosOperations = () => {
  const [selectedBanks, setSelectedBanks] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [customerBanks, setCustomerBanks] = useState([]);
  const [filters, setFilters] = useState({});

  const columns = [
    {
      Header: (row) => {
        return (
          <label className="container-custom-label mb-2">
            <input
              type="checkbox"
              checked={selectedRows.length === row.data.length}
              onChange={() => {
                if (selectedRows.length === row.data.length) {
                  setSelectedRows([]);
                  return;
                }
                const allData = row.data.reduce((total, item) => {
                  total.push(item.virtualPosReportId);
                  return total;
                }, []);
                setSelectedRows(allData);
              }}
            />
            <span className="checkmark" />
          </label>
        );
      },
      accessor: '',
      resizable: false,
      headerClassName: 'w-100 d-flex-justify-content-center',
      maxWidth: 50,
      sortable: false,
      filterable: false,

      Cell: (row) => {
        return (
          <>
            {row.value.status[0].info !== 'done' && (
              <label className="container-custom-label">
                <input
                  key={row.value.virtualPosReportId}
                  type="checkbox"
                  checked={selectedRows.find((e) => e === row.value.virtualPosReportId)}
                  onChange={() => {
                    if (selectedRows.find((e) => e === row.value.virtualPosReportId)) {
                      setSelectedRows(selectedRows.filter((e) => e !== row.value.virtualPosReportId));
                      return;
                    }
                    setSelectedRows([...selectedRows, row.value.virtualPosReportId]);
                  }}
                />
                <span className="checkmark" />
              </label>
            )}
          </>
        );
      },
    },
    {
      Header: 'Pos Rapor ID',
      accessor: 'virtualPosReportId',
    },

    {
      Header: 'Banka Adı',
      accessor: 'bankName',
    },
    {
      Header: 'Bayi No',
      accessor: 'dealerId',
    },
    {
      Header: 'Ödeme Net Tutar',
      accessor: 'expectedNetPaymentAmount',
      Cell: ({ value, original }) => {
        const { status } = original;
        const { code } = status[0];
        console.log(`code`, code);
        return (
          <span style={{ color: code === 'DifferentNetAmount' ? 'red' : '#000' }}>
            <div>{new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(value)} TL</div>
          </span>
        );
      },
    },
    {
      Header: 'Ödeme Tarihi',
      accessor: 'ExpectedPaymentDate',
      Cell: ({ value, original }) => {
        const { status } = original;
        const { code } = status[0];
        return (
          <span className="number" style={{ color: code === 'DifferentEffectiveDate' ? 'red' : '#000' }}>
            <div>{moment(value).format('DD/MM/YYYY')}</div>
          </span>
        );
      },
    },
    {
      Header: 'Fiili Tutar',
      accessor: 'transactionAmount',
      Cell: ({ value, original }) => {
        return <span>{new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(value)} TL</span>;
      },
    },
    {
      Header: 'Fiili Net Tutar',
      accessor: 'transactionNetAmount',
      Cell: ({ value, original }) => {
        const { status } = original;
        const { code } = status[0];
        return (
          <span style={{ color: code === 'DifferentNetAmount' ? 'red' : '#000' }}>
            <div>{new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(value)} TL</div>
          </span>
        );
      },
    },
    {
      Header: 'Fiili Ödeme Tarihi',
      accessor: 'valorDate',
      Cell: ({ value, original }) => {
        const { status } = original;
        const { code } = status[0];
        return (
          <span className="number" style={{ color: code === 'DifferentEffectiveDate' ? 'red' : '#000' }}>
            <div>{moment(value).format('DD/MM/YYYY')}</div>
          </span>
        );
      },
    },
    {
      Header: 'Banka Komisyon Tutarı',
      accessor: 'bankCommissionAmount',
    },
    {
      Header: 'Durum',
      Filtrable: false,
      accessor: 'status',
      Cell: ({ value }) => {
        const icon = getTip(value[0]);
        return (
          <Tooltip
            title={value[0].message}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            position="top"
            trigger="mouseenter">
            {icon}
          </Tooltip>
        );
      },
    },
  ];

  const bankSelectOptions = data.data.reduce((total, item) => {
    total.push({
      value: item.BankCode,
      label: item.BankName,
    });
    return total;
  }, []);

  const onBankSelectChange = (value) => {
    setSelectedBanks(value);
  };
  const onStatusSelectChange = (value) => {
    setSelectedStatus(value);
  };

  const statusFieldOptions = [
    { label: 'Muhasebeleştirilenler', value: 'success' },
    { label: 'Bloke Çözüm Tarihi Eşleşmeyenler', value: '' },
    { label: 'Fiili Ödeme Tutarı Eşleşmeyenler', value: '' },
    { label: 'Muhasebeştirilmeye hazır', value: '' },
  ];

  const handleSubmit = () => {
    const { transactionDate } = filters;
    const newRequestData = {
      companyId: 1,
      startDate: moment(transactionDate.startDate).format('YYYY-MM-DD'),
      endDate: moment(transactionDate.endDate).format('YYYY-MM-DD'),
      bankCode: selectedBanks?.value || null,
    };
    getEndOfDayData({ companyId: 1, ...newRequestData });
  };

  const getCustomerBanksData = async (data) => {
    try {
      const response = await getCustomerBanks(data);
      const bankOptions = response.data.reduce((total, item) => {
        total.push({ label: item.name, value: item.code });
        return total;
      }, []);
      setCustomerBanks(bankOptions);
    } catch (error) {}
  };

  const getEndOfDayData = async (data) => {
    try {
      const response = await getEndOfDay(data);
      setTableData(response);
    } catch (error) {}
  };
  const setFilterItems = (params) => {
    setFilters({ ...filters, ...params });
  };
  useEffect(() => {
    getCustomerBanksData({ companyId: 1 });
    getEndOfDayData({ companyId: 1 });
  }, []);

  return (
    <MainLayout>
      <Page className="endOfDayZReport px-3" title="Gün Sonu Raporu" icon={MdInsertChart}>
        <Row className="mx-1">
          <Col className="col-12 mb-3" style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
            <Card className="shadow-c p-4">
              <Row className="d-flex align-items-center">
                <Col sm="12" md="3">
                  <DatePicker
                    label="Tarih Aralığı"
                    onChange={({ startDate, endDate }) => {
                      setFilterItems({
                        transactionDate: { startDate, endDate },
                      });
                    }}
                  />
                </Col>
                <Col sm="12" md="3">
                  <SelectBox
                    label="Banka"
                    selectedValues={selectedBanks}
                    options={customerBanks}
                    onSelectChange={(value) => onBankSelectChange(value)}
                  />
                </Col>
                <Col sm="12" md="3">
                  <SelectBox
                    label="Durum"
                    isMulti
                    selectedValues={selectedStatus}
                    options={statusFieldOptions}
                    onSelectChange={(value) => onStatusSelectChange(value)}
                  />
                </Col>
                <Col sm="12" md="3">
                  <Button
                    outline
                    color="success"
                    className="mt-3"
                    onClick={() => {
                      handleSubmit();
                    }}>
                    Filtrele
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row className="mx-1">
          <Col className="col-12 mb-3" style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
            <Card className="shadow-c p-4">
              <div className="d-flex justify-content-beetwen action-bar ">
                {selectedRows.length > 0 && (
                  <div className="d-flex w-100 justify-content-start mb-3">
                    <Button outline color="info" className="mr-2" onClick={() => {}}>
                      <i className="fas fa-clipboard-check mr-2" />
                      Seçili mutabakat'ı kabul et
                    </Button>
                    <Button outline color="info" className="mr-2" onClick={() => {}}>
                      <i className="fas fa-envelope mr-2" />
                      Seçili işlemi mail gönder
                    </Button>

                    <Button outline color="info" className="mr-2" onClick={() => {}}>
                      <i className="fas fa-edit mr-2" />
                      Seçili işlem için not ekle
                    </Button>
                  </div>
                )}
                <div className="d-flex w-100 justify-content-end mb-3 export-bar">
                  <Button outline color="info" className="mr-4" onClick={() => {}}>
                    <i className="fas fa-paper-plane mr-2" />
                    Mail Gönder
                  </Button>

                  <Button outline color="primary" className="mr-4" onClick={() => {}}>
                    <i className="fas fa-file-csv mr-2" />
                    .CSV'e aktar
                  </Button>

                  <Button outline color="success">
                    <i className="fas fa-file-excel mr-2" />
                    .XSLX'e aktar
                  </Button>
                </div>
              </div>

              <ReactTooltip place="top" />
              <Table data={tableData} columns={columns} />
            </Card>
          </Col>
        </Row>
      </Page>
    </MainLayout>
  );
};

export default PosOperations;
