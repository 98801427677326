/* eslint-disable react/jsx-closing-bracket-location */
import React from 'react';
import { MdClearAll } from 'react-icons/md';
import { Button, Nav, Navbar } from 'reactstrap';

import BatLogo from 'assets/img/logo/British_American_Tobacco_logo.svg-800x456.png';
import ShellLogo from 'assets/img/logo/Shell_logo.svg';
import TurkcellLogo from 'assets/img/logo/TURKCELL_YATAY_ERKEK_LOGO.png';

import bn from 'utils/bemnames';

const bem = bn.create('header');

class Header extends React.Component {
  handleSidebarControlButton(event) {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  }

  render() {
    return (
      <Navbar light expand className={bem.b('bg-white')}>
        <Nav navbar className="mr-2">
          <Button outline onClick={this.handleSidebarControlButton} className="btn">
            <MdClearAll size={25} />
          </Button>
        </Nav>
        <Nav className={bem.e('nav-right')}>
          <img className="logoStyle" src={TurkcellLogo}/>
        </Nav>
      </Navbar>
    );
  }
}

export default Header;
