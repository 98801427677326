import config from '@b5a/config';

const prefix = config['storage-prefix'];

class Storage {
  get(key) {
    return localStorage.getItem(`${prefix}-${key}`);
  }

  set(key, params) {
    return localStorage.setItem(`${prefix}-${key}`, params);
  }

  remove(key) {
    return localStorage.removeItem(`${prefix}-${key}`);
  }

  clearAll() {
    return localStorage.clear();
  }
}

export default new Storage();
