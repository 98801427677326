import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from 'redux-store/configureStore';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'assets/scss/style.scss';
import 'assets/styles/reduction.scss';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import 'react-dropdown-tree-select/dist/styles.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-tippy/dist/tippy.css';

const { store } = configureStore(undefined);

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root'),
);

reportWebVitals();
