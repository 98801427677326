/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-closing-bracket-location */
import React, { useState, useEffect } from 'react';
import { MdTimeline, MdArrowDropDown, MdArrowDropUp, MdLinearScale } from 'react-icons/md';
import { Row, Col, Card, Button } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';

import { MainLayout } from 'components/Layout';
import Page from 'components/Page';
import Table from 'components/Table/Table';
import DatePicker from 'components/DatePicker';
import SelectBox from 'components/SelectBox';

import request from '@b5a/request';
const { getInstallmentConfig, getCustomerBanks } = request;

const getStatusIcon = (status) => {
  if (status === 'low') {
    return <MdArrowDropDown style={{ fontSize: 24, color: '#2ed47a' }} />;
  }
  if (status === 'high') {
    return <MdArrowDropUp style={{ fontSize: 24, color: '#f7685b' }} />;
  }
  return <MdLinearScale style={{ fontSize: 24, color: '#109cf1' }} />;
};

const getBankFilterOptions = (params) => {
  const options = params.reduce((total, item) => {
    total.push({
      value: item.bankCode,
      label: item.bankName,
    });
    return total;
  }, []);

  return uniqBy(options, 'value');
};

const onInstallmentSelectChange = (params) => {
  const options = params.reduce((total, item) => {
    total.push({
      value: Number(item.installmentCount),
      label: item.installmentCount,
    });
    return total;
  }, []);

  return uniqBy(options, 'value');
};

const getLabel = (param) => {
  switch (param) {
    case 'low':
      return 'Azalan';

    case 'equal':
      return 'Sabit';

    case 'high':
      return 'Artan';

    default:
      return 'Sabit';
  }
};

const getBankComision = (params) => {
  const options = params.reduce((total, item) => {
    total.push({
      value: item.bankCommissionStatus,
      label: getLabel(item.bankCommissionStatus),
    });
    return total;
  }, []);

  return uniqBy(options, 'value');
};

const tableColumns = [
  {
    Header: 'Banka Kodu',
    accessor: 'bankCode',
  },
  {
    Header: 'Banka Adı',
    accessor: 'bankName',
  },
  {
    Header: 'Taksit',
    accessor: 'installmentCount',
  },
  {
    Header: 'Komisyon Oranı',
    accessor: 'bankCommissionRate',
    Cell: ({ value, original }) => {
      const { bankCommissionStatus, originalBankCommissionRate } = original;
      const icon = getStatusIcon(bankCommissionStatus);
      return (
        <>
          {icon}
          <span className="number" data-tip={originalBankCommissionRate}>
            {value}
          </span>
        </>
      );
    },
  },
  {
    Header: 'Valör Gün Sayısı',
    accessor: 'installmentValorDayCount',
  },
  {
    Header: 'Başlangıç Tarihi',
    accessor: 'startDate',
    Cell: ({ value, original }) => {
      return <>{moment(value).format('DD/MM/YYYY')}</>;
    },
  },
  {
    Header: 'Bitiş Tarihi',
    accessor: 'endDate',
    Cell: ({ value, original }) => {
      return <>{moment(value).format('DD/MM/YYYY')}</>;
    },
  },
];

const Agreements = () => {
  const [filters, setFilters] = useState({});
  const [tableData, setTableData] = useState([]);
  const [customerBanks, setCustomerBanks] = useState([]);

  const setFilterItems = (params) => {
    setFilters({ ...filters, ...params });
  };

  const submitButton = () => {
    const { transactionDate } = filters;
    const newRequestData = {
      companyId: 1,
      startDate: moment(transactionDate.startDate).format('YYYY-MM-DD'),
      endDate: moment(transactionDate.endDate).format('YYYY-MM-DD'),
      bankCode: filters.bankCode?.value || null,
    };
    getInstallmentConfigData({ companyId: 1, ...newRequestData });
  };

  const getCustomerBanksData = async (data) => {
    try {
      const response = await getCustomerBanks(data);
      const bankOptions = response.data.reduce((total, item) => {
        total.push({ label: item.name, value: item.code });
        return total;
      }, []);
      setCustomerBanks(bankOptions);
    } catch (error) {}
  };

  const getInstallmentConfigData = async (data) => {
    try {
      const response = await getInstallmentConfig(data);
      setTableData(response.data.configs);
    } catch (error) {}
  };

  useEffect(() => {
    getCustomerBanksData({ companyId: 1 });
    getInstallmentConfigData({ companyId: 1, count: 30 });
  }, []);

  return (
    <MainLayout>
      <Page className="cashflowPage px-3" title="Taksit Ve Oranlar" icon={MdTimeline}>
        <Row className="mx-1">
          <Col className="col-12 mb-3" style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
            <Card className="table-card p-4 shadow-c">
              <Row>
                <Col sm="12" md="4">
                  <DatePicker
                    blockAfterDates={false}
                    label="İşlem Tarih Aralığı"
                    onChange={({ startDate, endDate }) => {
                      setFilterItems({
                        transactionDate: { startDate, endDate },
                      });
                    }}
                  />
                </Col>
                <Col sm="12" md="4">
                  <SelectBox
                    label="Banka"
                    placeholder="Seçim Yapınız"
                    selectedValues={filters.bankCode}
                    options={customerBanks}
                    onSelectChange={(value) => {
                      setFilterItems({
                        bankCode: value,
                      });
                    }}
                  />
                </Col>

                {/* <Col sm="12" md="3">
                  <SelectBox
                    label="Komisyon Oranı"
                    selectedValues={filters.installmentCount}
                    options={[]}
                    onSelectChange={(value) => {
                      setFilterItems({
                        bankCommissionStatus: [value],
                      });
                    }}
                  />
                </Col> */}
                <Col sm="12" md="4">
                  <Button sm="12" md="12" block outline color="success" className="mt-4" onClick={submitButton}>
                    Filtrele
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row className="mx-1">
          <Col className="col-12 mb-3" style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
            <ReactTooltip place="top" />
            <Card className="table-card p-4 shadow-c">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                className="mb-2">
                <div className="justify-content-start" style={{ color: '#90a0b7' }}>
                  {`${tableData.length} Adet sonuç bulundu.`}
                </div>
                <div className="justify-content-end mb-1">
                  <Button outline color="info" className="mr-4" onClick={() => {}}>
                    <i className="fas fa-paper-plane mr-2" />
                    Mail Gönder
                  </Button>

                  <Button outline color="primary" className="mr-4" onClick={() => {}}>
                    <i className="fas fa-file-csv mr-2" />
                    .CSV'e aktar
                  </Button>

                  <Button outline color="success">
                    <i className="fas fa-file-excel mr-2" />
                    .XSLX'e aktar
                  </Button>
                </div>
              </div>
              <Table data={tableData} columns={tableColumns} />
            </Card>
          </Col>
        </Row>
      </Page>
    </MainLayout>
  );
};

export default Agreements;
