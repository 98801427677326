/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable no-bitwise */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import { Row, Col, Card, /* FormGroup, Input, Label, */ Button } from 'reactstrap';

import { MdCreditCard } from 'react-icons/md';
import { MainLayout } from 'components/Layout';
import Page from 'components/Page';
import Table from 'components/Table/Table';
import DatePicker from 'components/DatePicker';
import SelectBox from 'components/SelectBox';
import data from './mockData.json';

import request from '@b5a/request';
const { getBankPos, getCustomerBanks } = request;

const getBankFilterOptions = (params) => {
  const options = params.reduce((total, item) => {
    total.push({
      value: item.bankCode,
      label: item.bankName,
    });
    return total;
  }, []);

  return uniqBy(options, 'value');
};

const getCompanyFilterOptions = (params) => {
  const options = params.reduce((total, item) => {
    total.push({
      value: item.baseCompanyVkn,
      label: item.baseCompanyName,
    });
    return total;
  }, []);

  return uniqBy(options, 'value');
};

const getDealerFilterOptions = (params) => {
  const options = params.reduce((total, item) => {
    total.push({
      value: item.companyVkn,
      label: item.companyName,
    });
    return total;
  }, []);

  return uniqBy(options, 'value');
};

const getPostPaymentIdFilterOptions = (params) => {
  const options = params.reduce((total, item) => {
    total.push({
      value: item.virtualPosPaymentId,
      label: item.virtualPosPaymentId,
    });
    return total;
  }, []);

  return uniqBy(options, 'value');
};

const getTerminalNo = (params) => {
  const options = params.reduce((total, item) => {
    total.push({
      value: item.terminalNo,
      label: item.terminalNo,
    });
    return total;
  }, []);

  return uniqBy(options, 'value');
};

const PosOperations = () => {
  const [filters, setFilters] = useState({});
  const [tableData, setTableData] = useState(data.data);
  const [customerBanks, setCustomerBanks] = useState([]);

  const [tableRowCount, setTableRowCount] = useState(10);
  const [tablePage, seTablePage] = useState(1);
  const setFilterItems = (params) => {
    setFilters({ ...filters, ...params });
  };

  const START_ROW_INDEX = tablePage * tableRowCount;
  const END_ROW_INDEX = tablePage * tableRowCount + tableRowCount;

  const columns = [
    {
      Header: 'Ana Şirket',
      accessor: 'baseCompanyName',
    },
    {
      Header: 'Şube',
      accessor: 'companyName',
    },
    {
      Header: 'Terminal No',
      accessor: 'terminalNo',
    },
    {
      Header: 'Pos Ödeme No',
      accessor: 'virtualPosPaymentId',
    },
    {
      Header: 'Banka Adı',
      accessor: 'bankName',
    },
    {
      Header: 'İşlem Tarihi',
      accessor: 'transactionDate',
      Cell: ({ value }) => <span className="number">{moment(value).format('DD/MM/YYYY')}</span>,
    },
    {
      Header: 'İşlem Tutarı',
      accessor: 'transactionAmount',
      Cell: ({ value }) => <span>{new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(value)}</span>,
      Footer: (
        <span>
          <strong>
            {new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(
              tableData.reduce((total, item, index) => {
                if (item.transactionAmount !== 'NULL' && index >= START_ROW_INDEX && index < END_ROW_INDEX) {
                  total += Number(item.transactionAmount);
                }
                return total;
              }, 0),
            )}{' '}
            TL
          </strong>
        </span>
      ),
    },
    {
      Header: 'Komisyon Tutarı',
      accessor: 'bankCommissionAmount',
      Cell: ({ value }) => <span>{new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(value)}</span>,
      Footer: (
        <span>
          <strong>
            {new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(
              tableData.reduce((total, item, index) => {
                if (item.bankCommissionAmount !== 'NULL' && index >= START_ROW_INDEX && index < END_ROW_INDEX) {
                  total += Number(item.bankCommissionAmount);
                }
                return total;
              }, 0),
            )}{' '}
            TL
          </strong>
        </span>
      ),
    },
    {
      Header: <span style={{ color: '#45b649' }}>Fiili Net Tutar</span>,
      accessor: 'transactionNetAmount',
      Cell: ({ value }) => (
        <span style={{ color: '#45b649' }}>{new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(value)}</span>
      ),
      Footer: (
        <span style={{ color: '#45b649' }}>
          <strong>
            {new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(
              tableData.reduce((total, item, index) => {
                if (item.transactionNetAmount !== 'NULL' && index >= START_ROW_INDEX && index < END_ROW_INDEX) {
                  total += Number(item.transactionNetAmount);
                }
                return total;
              }, 0),
            )}{' '}
            TL
          </strong>
        </span>
      ),
    },
  ];

  const submitButton = () => {
    getBankPosData({
      companyId: 1,
      bankCode: filters?.bankCode?.value || null,
      startDate: filters.transactionDate.startDate.format('YYYY-MM-DD'),
      endDate: filters.transactionDate.endDate.format('YYYY-MM-DD'),
    });
  };

  const getBankPosData = async (data) => {
    try {
      const response = await getBankPos(data);
      console.log(`response`, response);
      setTableData(response.data);
    } catch (error) {}
  };

  const getCustomerBanksData = async (data) => {
    try {
      const response = await getCustomerBanks(data);
      const bankOptions = response.data.reduce((total, item) => {
        total.push({ label: item.name, value: item.code });
        return total;
      }, []);
      setCustomerBanks(bankOptions);
    } catch (error) {}
  };

  useEffect(() => {
    getBankPosData({ companyId: 1, requestType: 0 });
    getCustomerBanksData({ companyId: 1 });
  }, []);

  useEffect(() => {
    console.log('sa', {
      START_ROW_INDEX,
      END_ROW_INDEX,
    });
  }, [tableRowCount, tablePage]);
  return (
    <MainLayout>
      <Page className="posOperationPage px-3" title="POS İşlemleri" icon={MdCreditCard}>
        <Row className="mx-1">
          <Col className="col-12 mb-3" style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
            <Card className="table-card p-4 shadow-c">
              <Row>
                <Col sm="12" md="3">
                  <DatePicker
                    showClearDates
                    label="İşlem Tarih Aralığı"
                    onChange={({ startDate, endDate }) => {
                      setFilterItems({
                        transactionDate: { startDate, endDate },
                      });
                    }}
                  />
                </Col>
                <Col sm="12" md="3">
                  <SelectBox
                    label="Banka"
                    placeholder="Seçim Yapınız"
                    selectedValues={filters.bankCode}
                    options={customerBanks}
                    onSelectChange={(value) => {
                      setFilterItems({
                        bankCode: value,
                      });
                    }}
                  />
                </Col>

                <Col sm="12" md="2" className="mt-2">
                  <Button sm="12" md="12" block outline color="success" className="mt-4" onClick={submitButton}>
                    Ara
                  </Button>
                </Col>
              </Row>
              {/* <Row>
                <Col sm="12" md="2">
                  <SelectBox
                    label="Ana Şirket"
                    placeholder="Seçim Yapınız"
                    isMulti
                    selectedValues={filters.baseCompanyVkn}
                    options={getCompanyFilterOptions(tableData)}
                    onSelectChange={(values) =>
                      setFilterItems({
                        baseCompanyVkn: values,
                      })
                    }
                  />
                </Col>
                <Col sm="12" md="3">
                  <SelectBox
                    label="Şube"
                    placeholder="Seçim Yapınız"
                    isMulti
                    selectedValues={filters.companyVkn}
                    options={getDealerFilterOptions(tableData)}
                    onSelectChange={(value) =>
                      setFilterItems({
                        companyVkn: value,
                      })
                    }
                  />
                </Col>

                <Col sm="12" md="2">
                  <SelectBox
                    label="Terminal No"
                    placeholder="Seçim Yapınız"
                    isMulti
                    selectedValues={filters.terminalNo}
                    options={getTerminalNo(tableData)}
                    onSelectChange={(value) => {
                      setFilterItems({
                        terminalNo: value,
                      });
                    }}
                  />
                </Col>
                <Col sm="12" md="3">
                  <SelectBox
                    label="POS Ödeme NO"
                    placeholder="Seçim Yapınız"
                    isMulti
                    selectedValues={filters.virtualPosPaymentId}
                    options={getPostPaymentIdFilterOptions(tableData)}
                    onSelectChange={(value) => {
                      setFilterItems({
                        virtualPosPaymentId: value,
                      });
                    }}
                  />
                </Col>
                <Col sm="12" md="2" className="mt-2">
                  <Button sm="12" md="12" block outline color="success" className="mt-4" onClick={submitButton}>
                    Filtrele
                  </Button>
                </Col>
              </Row> */}
            </Card>
          </Col>
        </Row>
        <Row className="mx-1">
          <Col className="col-12 mb-3" style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
            <Card className="table-card p-4 shadow-c">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                className="mb-2">
                <div className="justify-content-start" style={{ color: '#90a0b7' }}>
                  {`${tableData.length} Adet sonuç bulundu.`}
                </div>
                <div className="justify-content-end mb-1">
                  <Button outline color="info" className="mr-4" onClick={() => {}}>
                    <i className="fas fa-paper-plane mr-2" />
                    Mail Gönder
                  </Button>

                  <Button outline color="primary" className="mr-4" onClick={() => {}}>
                    <i className="fas fa-file-csv mr-2" />
                    .CSV'e aktar
                  </Button>

                  <Button outline color="success">
                    <i className="fas fa-file-excel mr-2" />
                    .XSLX'e aktar
                  </Button>
                </div>
              </div>
              <Table
                specialSettings={{
                  pageSizeOptions: [5, 10, 15, 20],
                  onPageSizeChange: (ROW_COUNT) => {
                    setTableRowCount(ROW_COUNT);
                  },
                  onPageChange: (CURRENT_PAGE) => {
                    seTablePage(CURRENT_PAGE);
                  },
                }}
                data={tableData}
                columns={columns}
              />
            </Card>
          </Col>
        </Row>
      </Page>
    </MainLayout>
  );
};

export default PosOperations;
