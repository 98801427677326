import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';

const SelectBox = ({
  label,
  options = [],
  selectedValues = [],
  onSelectChange = () => {},
  isMulti = false,
  isClearable = true,
  ...props
}) => (
  <FormGroup className={props.formClassName}>
    {label && <Label for="exampleEmail">{label}</Label>}
    <Select
      isMulti={isMulti}
      value={selectedValues}
      isClearable={isClearable}
      options={options}
      onChange={(value) => onSelectChange(value)}
      {...props}
    />
  </FormGroup>
);

export default SelectBox;
