/* eslint-disable react/jsx-closing-bracket-location */
import React from 'react';
import classnames from 'classnames';
import bn from 'utils/bemnames';

import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import Typography from './Typography';

const bem = bn.create('page');

const Page = ({ title, breadcrumbs, tag: Tag, className, children, icon: Icon, ...restProps }) => {
  const classes = bem.b(className);

  return (
    <Tag className={classes} {...restProps}>
      <div className={classnames(bem.e('header'), 'pb-3')}>
        {title && typeof title === 'string' ? (
          <div
            className="col-12 row py-3"
            style={{
              alignItems: 'center',
              color: '#109cf1',
              borderBottomWidth: 1,
              borderBottomColor: '#c2cfe0',
            }}>
            {Icon && <Icon style={{ fontSize: 44, paddingRight: 8 }} />}
            <Typography type="h1" className={bem.e('title')}>
              {title}
            </Typography>
          </div>
        ) : (
          title
        )}
        {breadcrumbs && (
          <Breadcrumb className={bem.e('breadcrumb')}>
            <BreadcrumbItem>Home</BreadcrumbItem>
            {breadcrumbs.length &&
              breadcrumbs.map(({ name, active }, index) => (
                <BreadcrumbItem key={index.toString()} active={active}>
                  {name}
                </BreadcrumbItem>
              ))}
          </Breadcrumb>
        )}
      </div>
      {children}
    </Tag>
  );
};

Page.defaultProps = {
  tag: 'div',
  title: '',
};

export default Page;
