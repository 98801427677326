import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink } from 'reactstrap';

const NavContainer = ({ tabData, activeTab, toggle }) => (
  <Nav tabs>
    {tabData?.map((item) => {
      const { tabName, key: activeKey } = item;
      return (
        <>
          <NavItem key={tabName} className={activeTab === activeKey && 'active'}>
            <NavLink
              onClick={() => {
                toggle(activeKey);
              }}>
              {tabName}
            </NavLink>
          </NavItem>
        </>
      );
    })}
  </Nav>
);

NavContainer.propTypes = {
  activeTab: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  tabData: PropTypes.arrayOf({
    tabName: PropTypes.string,
  }).isRequired,
};

export default NavContainer;
