import LoginForm from 'components/Login/LoginForm';
import React, { useState } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import req from '@b5a/request/index';
import './styles.scss';

const { login } = req;
const isSignup = false;

const BusinessLoginForm = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [businessCode, setBusinessCode] = useState('');
  const [error, setError] = useState(false);

  const businessCodeControl = businessCode.length > 0;

  const businessHandleSubmit = async () => {
    if (businessCodeControl) {
      const loginResponse = await login(email, password);
      localStorage.setItem('session', true);
      localStorage.setItem('token', loginResponse.data.token);
      window.location = '/sirket-sistem-girisi';
    } else {
      setError(true);
    }
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      businessHandleSubmit();
    }
  };

  const {
    usernameLabel,
    usernameInputProps,
    passwordLabel,
    passwordInputProps,
    confirmPasswordLabel,
    confirmPasswordInputProps,
  } = props;

  return (
    <LoginForm handleSubmit={businessHandleSubmit} error={error}>
      <FormGroup>
        <Label for={usernameLabel} className="customInputLabel">
          {usernameLabel}
        </Label>
        <Input
          {...usernameInputProps}
          onBlur={() => {
            setError(false);
          }}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label for="iş Yeri Kodu" className="customInputLabel">
          İş yeri kodu
        </Label>
        <Input
          type="text"
          placeholder="İş yeri kodunuz"
          className="customInput py-4"
          onBlur={() => {
            setError(false);
          }}
          value={businessCode}
          onChange={(e) => {
            setBusinessCode(e.target.value);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label for={passwordLabel} className="customInputLabel">
          {passwordLabel}
        </Label>
        <Input
          {...passwordInputProps}
          onBlur={() => {
            setError(false);
          }}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          onKeyUp={handleKeyUp}
        />
      </FormGroup>
      {isSignup && (
        <FormGroup>
          <Label for={confirmPasswordLabel}>{confirmPasswordLabel}</Label>
          <Input {...confirmPasswordInputProps} />
        </FormGroup>
      )}
      <FormGroup check>
        <Label check>
          <Input type="checkbox" />
          {isSignup ? 'Agree the terms and policy' : 'Beni Hatırla'}
        </Label>
      </FormGroup>
    </LoginForm>
  );
};

BusinessLoginForm.defaultProps = {
  usernameLabel: 'Kullanıcı Adı',
  usernameInputProps: {
    type: 'username',
    placeholder: '',
    className: 'customInput py-4',
  },
  passwordLabel: 'Şifre',
  passwordInputProps: {
    type: 'password',
    placeholder: '******',
    className: 'customInput py-4',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};

export default BusinessLoginForm;
