import React, { useState } from 'react';
import NavContainer from './NavContainer';
import TabContainer from './TabContainer';
import './styles.scss';

const Tab = ({ tabData, tabContent }) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div className="tabContent">
      <NavContainer tabData={tabData} activeTab={activeTab} toggle={toggle} />
      <TabContainer tabContent={tabContent} activeTab={activeTab} />
    </div>
  );
};

export default Tab;
