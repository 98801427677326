/* eslint-disable no-param-reassign */
import React from 'react';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';

import './style.scss';

const defaultSettings = {
  sortable: true,
  className: 'react-custom-table',
  previousText: 'Önceki Sayfa',
  nextText: 'Sonraki Sayfa',
  pageText: 'Sayfa : ',
  ofText: 'Toplam Sayfa :',
  rowsText: ' Satır ',
  filterable: true,
  defaultPageSize: 10,
  defaultFilterMethod: (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? String(row[id]).toLowerCase().startsWith(filter.value.toLowerCase()) : true;
  },
  defaultSortMethod: (a, b) => {
    // force null and undefined to the bottom
    a = a === null || a === undefined ? '' : a;
    b = b === null || b === undefined ? '' : b;
    // force any string values to lowercase
    a = typeof a === 'string' ? a.toLowerCase() : a;
    b = typeof b === 'string' ? b.toLowerCase() : b;
    // Return either 1 or -1 to indicate a sort priority
    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }
    // returning 0, undefined or any falsey value will use subsequent sorts or
    // the index as a tiebreaker
    return 0;
  },
};

const Table = ({ classNames, data, columns, specialSettings, ...props }) => (
  <div>
    <ReactTable
      className={classNames}
      {...{ ...defaultSettings, ...specialSettings }}
      data={data}
      columns={columns}
      {...props}
    />
  </div>
);

export default Table;
