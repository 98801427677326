/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-closing-bracket-location */
import React from 'react';
import classnames from 'classnames';
import { MdDashboard, MdInsertChart, MdWeb, MdCreditCard, MdTimeline, MdExitToApp } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { Nav, NavItem, NavLink as BSNavLink } from 'reactstrap';
import bn from 'utils/bemnames';

const logo = require('assets/img/logo-text.png');

const navItems = [
  { to: '/', name: 'Anasayfa', exact: true, Icon: MdDashboard },
  { to: '/pos-islemleri', name: 'POS İşlemleri', exact: false, Icon: MdCreditCard },
  { to: '/gun-sonu-raporu', name: 'Gün Sonu Raporu', exact: false, Icon: MdInsertChart },
  { to: '/nakit-akisi', name: 'Nakit Akışı', exact: false, Icon: MdWeb },
  { to: '/anlasmalar', name: 'POS Bilgileri', exact: false, Icon: MdTimeline },
];

const bem = bn.create('sidebar');

const Sidebar = () => {
  const asideClass = classnames(bem.b(), {
    'cr-sidebar--open': window.screen.width > 1024,
  });
  return (
    <aside className={asideClass}>
      <div className={bem.e('content')}>
        <Nav
          vertical
          className="text-center"
          style={{
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <div className="text-center py-3 my-3">
            <img src={logo} style={{ height: 55, zIndex: 99 }} alt="logo" />
          </div>
        </Nav>
        <hr style={{ borderWidth: '0.2rem', borderColor: '#ebeff2' }} />
      </div>
      <div className={bem.e('content')}>
        <Nav vertical>
          {navItems.map(({ to, name, exact, Icon }, index) => (
            <NavItem key={index.toString()} className={bem.e('nav-item')}>
              <BSNavLink id={`navItem-${name}-${index}`} tag={NavLink} to={to} activeClassName="active" exact={exact}>
                <Icon className={bem.e('nav-item-icon')} style={{ width: 28, height: 28 }} />
                <span>{name}</span>
              </BSNavLink>
            </NavItem>
          ))}
        </Nav>
        <hr style={{ borderWidth: '0.2rem', borderColor: '#ebeff2' }} />
        <Nav vertical>
          <NavItem className={bem.e('nav-item')} style={{ opacity: 1 }}>
            <BSNavLink
              style={{ borderTopWidth: 1, borderTopColor: 'red' }}
              onClick={() => {
                localStorage.removeItem('session');
                window.location = '/giris';
              }}>
              <MdExitToApp className={bem.e('nav-item-icon')} />
              <span>Çıkış Yap</span>
            </BSNavLink>
          </NavItem>
        </Nav>
      </div>
      <div
        style={{
          display: 'flex',
          zIndex: 44,
          position: 'absolute',
          bottom: 10,
          left: 0,
          right: 0,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <span style={{ fontWeight: 400, textAlign: 'center', color: '#c2cfe0', alignSelf: 'center' }}>Beta 1.0.0</span>
      </div>
    </aside>
  );
};

export default Sidebar;
