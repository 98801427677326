/* eslint-disable react/jsx-closing-bracket-location */
import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Button } from 'reactstrap';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import 'moment/locale/tr';

import './styles.scss';

moment.locale('tr');

const fastDates = [
  {
    count: 1,
    text: 'Son 1 ay',
  },
  {
    count: 3,
    text: 'Son 3 ay',
  },
  {
    count: 6,
    text: 'Son 6 ay',
  },
  {
    count: 12,
    text: 'Son 12 ay',
  },
];

const DatePicker = ({
  blockAfterDates = true,
  label,
  startDatePlaceholderText = 'Başlangıç',
  endDatePlaceholderText = 'Bitiş',
  onChange = () => {},
  reset = false,
  ...props
}) => {
  const [date, setDate] = useState({
    startDate: moment(Date.now()).subtract(1, 'days'),
    endDate: moment(),
  });
  const [focusedInputx, setFocusedInput] = useState(null);

  const [selectedFastDate, setSelectedFastDate] = useState(null);

  const fastDateSelect = (count) => {
    setDate({ startDate: moment(Date.now()).subtract(count, 'months'), endDate: moment() });
    setSelectedFastDate(count);
  };

  useEffect(() => {
    onChange(date);
  }, [date]);

  useEffect(() => {
    if (reset) {
      setDate({ startDate: null, endendDate: null });
    }
  }, [reset]);

  return (
    <FormGroup>
      {label && <Label for="exampleEmail">{label}</Label>}
      <DateRangePicker
        isOutsideRange={(day) => blockAfterDates && day.isAfter(moment())}
        focusedInput={focusedInputx}
        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
        displayFormat={() => 'DD/MM/YYYY'}
        block
        small
        hideKeyboardShortcutsPanel
        startDatePlaceholderText={startDatePlaceholderText}
        endDatePlaceholderText={endDatePlaceholderText}
        renderCalendarInfo={() => (
          <div className="px-2 py-4 d-flex justify-content-around">
            {fastDates.map((dates) => (
              <Button
                className="px-2"
                onClick={() => {
                  fastDateSelect(dates.count);
                }}
                outline={selectedFastDate !== dates.count}
                color="primary">
                {dates.text}
              </Button>
            ))}
          </div>
        )}
        calendarInfoPosition="bottom"
        startDate={date.startDate}
        startDateId="your_unique_start_date_id"
        endDate={date.endDate}
        endDateId="your_unique_end_date_id"
        onDatesChange={({ startDate, endDate }) => {
          setDate({ startDate, endDate });
        }}
        {...props}
      />
    </FormGroup>
  );
};

export default DatePicker;
