import { jwtDecoder } from '@b5a/helpers';

class Token {
  isExpired(token) {
    if (!token) return new Error('Token not found!');

    const { exp } = this.getDecoded(token);
    const now = Date.now();

    if (now > exp) {
      return false;
    }

    return true;
  }

  getDecoded(token) {
    return jwtDecoder(token);
  }
}

export default new Token();
