import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

import { MdDashboard } from 'react-icons/md';
import { FaArrowsAltH, FaArrowDown, FaArrowUp } from 'react-icons/fa';

import Page from 'components/Page';
import { MainLayout } from 'components/Layout';
import IconWidget from 'components/IconWidget';
import SelectBox from 'components/SelectBox';

import { Bar, Line, Doughnut } from 'react-chartjs-2';

import request from '@b5a/request/index';

const screenSize = window.screen.width > 575;
const optionsLine = {
  legend: {
    position: screenSize ? 'right' : 'bottom',
  },
};
const fastDateOptionsBar = [
  { label: 'Son 1 Ay', value: 1 },
  { label: 'Son 3 Ay', value: 3 },
  { label: 'Son 6 Ay', value: 6 },
];
const installmentOptions = [
  { label: 'Tek Çekim', value: 1 },
  { label: '3 Taksit', value: 3 },
  { label: '6 Taksit', value: 6 },
  { label: '9 Taksit', value: 9 },
];

const { getCollectionAnalysis, getIncomeAndCommission, getBankCommissionRate, getTotalCollectionByBank } = request;
export const DashboardPage = () => {
  const [collectionAnalysis, setCollectionAnalysis] = useState([]);
  const [incomeAndCommission, setIncomeAndCommission] = useState({});

  const [bankCommissionRate, setBankCommissionRate] = useState({});
  const [totalCollectionByBank, setTotalCollectionByBank] = useState({});

  const [selectedBarChart, setSelectedBarChart] = useState({ label: 'Son 1 Ay', value: 1 });
  const [selectedPieChart, setSelectedPieChart] = useState({ label: 'Son 1 Ay', value: 1 });
  const [installmentChoice, setInstallmentChoice] = useState({ label: 'Tek Çekim', value: 1 });
  // const [selectedPieChart, setSelectedPieChart] = useState({ label: 'Tek Çekim', value: 1 });

  const incomeAndCommissionDataMonthSelect = (value) => {
    setSelectedBarChart(value);
    getIncomeAndCommissionData(value.value);
  };

  const onTotalCollectionByBankMonthSelect = (value) => {
    setSelectedPieChart(value);
    getTotalCollectionByBankData(value.value);
  };

  const onInstallmentSelect = (value) => {
    setInstallmentChoice(value);
    getBankCommissionRateData(value.value);
  };

  async function getCollectionAnalysisData(month = 1) {
    try {
      const response = await getCollectionAnalysis(month);
      const newResponse = response.data.data.reduce((total, item) => {
        if (item.type === 'Collection') {
          total.push({
            bgColor: 'primary',
            icon: FaArrowUp,
            label: 'Tahsilat',
            title: item.balance,
            subtitle: `${item.count} Adet`,
          });
        } else if (item.type === 'Commission') {
          total.push({
            bgColor: 'secondary',
            icon: FaArrowDown,
            label: 'Komisyon',
            title: item.balance,
            subtitle: `${item.count} Adet`,
          });
        } else {
          total.push({
            bgColor: 'success',
            icon: FaArrowDown,
            label: 'Net Tahsilat',
            title: item.balance,
            subtitle: `${item.count} Adet`,
          });
        }
        return total;
      }, []);
      setCollectionAnalysis(newResponse);
    } catch (error) {}
  }
  async function getIncomeAndCommissionData(month = 1) {
    try {
      const response = await getIncomeAndCommission(month);
      setIncomeAndCommission(response);
    } catch (error) {}
  }
  async function getBankCommissionRateData(Installment = 1) {
    try {
      const response = await getBankCommissionRate(Installment);
      setBankCommissionRate(response);
    } catch (error) {}
  }
  async function getTotalCollectionByBankData(Installment = 1) {
    try {
      const response = await getTotalCollectionByBank(Installment);
      setTotalCollectionByBank(response);
    } catch (error) {}
  }

  useEffect(() => {
    getCollectionAnalysisData();
    getIncomeAndCommissionData();
    getBankCommissionRateData();
    getTotalCollectionByBankData();
  }, []);
  return (
    <MainLayout>
      <Page className="DashboardPage px-3" title="POS Tahsilat Analiz Ekranı" icon={MdDashboard}>
        <Row>
          {collectionAnalysis.map(({ bgColor, icon, label, title, subtitle, ...restProps }, index) => (
            <Col key={index.toString()} lg={4} md={6} sm={6} xs={12} className="mb-3">
              <IconWidget
                bgColor={bgColor}
                icon={icon}
                label={label}
                title={title}
                subtitle={subtitle}
                {...restProps}
              />
            </Col>
          ))}
        </Row>
        <Row>
          <Col xl={6} lg={12} md={12}>
            <Card className="shadow-c">
              <CardHeader className="d-flex justify-content-between align-items-baseline ">
                <>Tahsilat-Komisyon Durumu</>
                <SelectBox
                  formClassName="w-35"
                  placeholder="Hızlı Tarih Seçiniz"
                  selectedValues={selectedBarChart}
                  isClearable={false}
                  options={fastDateOptionsBar}
                  onSelectChange={(value) => {
                    incomeAndCommissionDataMonthSelect(value);
                  }}
                />
              </CardHeader>
              <CardBody>
                <Bar data={incomeAndCommission} />
              </CardBody>
            </Card>
          </Col>

          <Col xl={6} lg={12} md={12}>
            <Card className="shadow-c">
              <CardHeader className="d-flex justify-content-between align-items-baseline ">
                <>Toplam Tahsilat/Banka Dağılımı</>
                <SelectBox
                  formClassName="w-35"
                  placeholder="Hızlı Tarih Seçiniz"
                  selectedValues={selectedPieChart}
                  isClearable={false}
                  options={fastDateOptionsBar}
                  onSelectChange={(value) => {
                    onTotalCollectionByBankMonthSelect(value);
                  }}
                />
              </CardHeader>
              <CardBody>
                <Doughnut width={screenSize ? null : '200%'} options={optionsLine} data={totalCollectionByBank} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl={6} lg={12} md={12}>
            <Card className="shadow-c">
              <CardHeader className="d-flex justify-content-between align-items-baseline ">
                <>Banka Komisyon Oranları</>
                <SelectBox
                  formClassName="w-35"
                  placeholder="Taksit Sayısı Seçin"
                  selectedValues={installmentChoice}
                  isClearable={false}
                  options={installmentOptions}
                  onSelectChange={(value) => {
                    onInstallmentSelect(value);
                  }}
                />
              </CardHeader>
              <CardBody>
                <Line width={screenSize ? null : '200%'} options={optionsLine} data={bankCommissionRate} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    </MainLayout>
  );
};

export default DashboardPage;
