import Services from '@b5a/services';
import { storage } from '@b5a/helpers';
import { storageKeys } from '@b5a/config/constants';
import config from '@b5a/config';

const userToken = storage.get(storageKeys.token);

export const { axios, client, setHeaders } = new Services({
  baseURL: config?.api?.baseURL || null,
  headers: {
    Authorization: userToken ? `Bearer ${userToken}` : undefined,
  },
});

export default {
  setHeaders,
  users: () => axios.get('/users/buraksmn'),
  login: (kullaniciAdi, sifre) => axios.post('/Login', { kullaniciAdi, sifre }),

  getCollectionAnalysis: (lastMonthCount) =>
    axios
      .get('/Pos/collectionAnalysis', {
        params: {
          lastMonthCount,
          CompanyId: 1,
        },
      })
      .then((res) => {
        return res;
      }),

  getIncomeAndCommission: (lastMonthCount) =>
    axios
      .get('/Pos/incomeAndCommission', {
        params: {
          lastMonthCount,
          CompanyId: 1,
        },
      })
      .then((res) => {
        const income = [];
        const banks = [];
        const commission = [];
        res.data.data.forEach((item) => {
          banks.push(item.bankName);
          income.push(item.income);
          commission.push(item.commission);
        });
        const datasets = [
          {
            label: 'Tahsilat (TRY)',
            backgroundColor: '#6a82fb',
            borderColor: '#6a82fb',
            borderWidth: 1,
            borderRadius: 8,
            size: 16,
            data: income,
          },
          {
            label: 'Komisyon',
            backgroundColor: '#fc5c7d',
            borderColor: '#fc5c7d',
            borderWidth: 1,
            data: commission,
          },
        ];
        const newResponse = {
          labels: banks,
          datasets,
        };
        return newResponse;
      }),

  getTotalCollectionByBank: (lastMonthCount) =>
    axios
      .get('/Pos/totalCollectionByBank', {
        params: {
          lastMonthCount,
          CompanyId: 1,
        },
      })
      .then((res) => {
        const banks = [];
        const incomes = [];
        const colors = [];

        res.data.data.forEach((item) => {
          banks.push(item.bankName);
          incomes.push(item.balance);
          colors.push(item.color);
        });

        const newResponse = {
          datasets: [
            {
              data: incomes,
              backgroundColor: colors,
            },
          ],
          labels: banks,
        };
        return newResponse;
      }),

  getBankCommissionRate: (Installment) =>
    axios
      .get('/Pos/bankCommissionRate', {
        params: {
          Installment,
          CompanyId: 1,
        },
      })
      .then((res) => {
        const months = [
          'Ocak',
          'Şubat',
          'Mart',
          'Nisan',
          'Mayıs',
          'Haziran',
          'Temmuz',
          'Ağustos',
          'Eylül',
          'Ekim',
          'Kasım',
          'Aralık',
        ];
        const newDatasets = [];
        res.data.data.forEach((item) => {
          const newRates = [];
          item.rates.forEach((rate) => {
            newRates.push(rate.rate);
          });
          newDatasets.push({
            label: item.bankName,
            backgroundColor: item.color,
            borderColor: item.color,
            borderWidth: 1,
            pointRadius: 2,
            data: newRates,
            fill: false,
          });
        });
        return {
          labels: months,
          datasets: newDatasets,
        };
      }),

  getCashFlow: (data) => axios.get('/Pos/cashFlow', { params: { ...data } }),
  getEndOfDay: (data) =>
    axios.get('/Pos/getEndOfDay', { params: { ...data } }).then((res) => res.data.data.transactions),
  getCustomerBanks: (data) => axios.get('/Bank/all', { params: { ...data } }).then((res) => res.data),
  getInstallmentConfig: (data) => axios.get('/Pos/installmentConfig', { params: { ...data } }).then((res) => res.data),
  getBankPos: (data) => axios.get('/bankpos/all', { params: { ...data } }).then((res) => res.data),
};
