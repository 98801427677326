import React from 'react';
import PropTypes from 'prop-types';
import { TabContent, TabPane } from 'reactstrap';

const TabContainer = ({ tabContent, activeTab }) => (
  <TabContent activeTab={activeTab}>
    {tabContent.map((item) => {
      const { key, content } = item;
      return <TabPane tabId={key}>{content}</TabPane>;
    })}
  </TabContent>
);

TabContainer.propTypes = {
  activeTab: PropTypes.string.isRequired,
  tabContent: PropTypes.arrayOf({
    key: PropTypes.string.isRequired,
    content: PropTypes.element.isRequired,
  }).isRequired,
};

export default TabContainer;
